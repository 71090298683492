import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GenericPage from '../GenericPage';
import { useUserContext } from '../../contexts/user-context';
import Loaders from '../Loaders';
import loaderTypes from '../Loaders/loaderTypes';
import Layout from '../MyLayout';

const propTypes = {
  content: PropTypes.shape({
    theme: PropTypes.string,
  }).isRequired,
};

const Standalone = ({ content }) => {
  const { theme } = content;
  const { profile, loadingProfile, loading } = useUserContext();
  const { hogwartsHouse } = profile;
  const [layoutTheme, setLayoutTheme] = useState(null);

  useEffect(() => {
    if (!loading && !loadingProfile) {
      if (theme === 'user-house') {
        setLayoutTheme(
          profile.hogwartsHouse ? `user-${hogwartsHouse.toLowerCase()}` : 'user-no-house',
        );
      } else {
        setLayoutTheme(theme);
      }
    }
  }, [theme, profile, hogwartsHouse, loading, loadingProfile]);

  if (loading || loadingProfile) return <Loaders type={loaderTypes.timeturner} />;

  return (
    <Layout theme={layoutTheme}>
      <GenericPage pageData={content} />
    </Layout>
  );
};

Standalone.propTypes = propTypes;
export default Standalone;
