
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import PropTypes from 'prop-types';
import logger from '@utils/logger';
import HeadMeta from '@components/HeadMeta';
import { isValidGenericPageURL, fetchContent, fetchAllPagedContent, withNavigation, } from '../src/utils/contentfulHelpers';
import Standalone from '../src/components/Standalone';
import Error404 from '../src/components/Error404';
const DEFAULT_REVALIDATE = Number(process.env.REVALIDATION_TIMEOUT_LONG) || 60 * 5;
const ERROR_REVALIDATE = Number(process.env.REVALIDATION_TIMEOUT_ERROR) || 30;
const propTypes = {
    content: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]).isRequired
};
const DynamicPage = ({ content }) => {
    if (!content) {
        return <Error404 />;
    }
    const { searchTitle, searchDescription, openGraphTitle, openGraphDescription, openGraphImage, twitterCardImage, } = content;
    const ogImage = openGraphImage?.image?.file?.url ?? '';
    const robots = content?.metaTag ?? '';
    const canonicalUrl = content?.canonicalUrl ?? `/${content.externalId}`;
    const twitterImage = twitterCardImage?.image?.file?.url ?? '';
    return (<>
      <HeadMeta title={searchTitle} description={searchDescription} ogTitle={openGraphTitle} ogDescription={openGraphDescription} ogImage={`https:${ogImage}?w=1200&fit=fill&f=top`} twitterImage={`https:${twitterImage}?w=1200&fit=fill&f=top`} robots={robots} canonicalUrl={canonicalUrl}/>
      <Standalone content={content}/>
    </>);
};
async function getStaticProps(context) {
    const { externalId } = context.params;
    let content;
    let errors;
    let props;
    const pageRoute = externalId ? `/${externalId}` : '/';
    const location = `Page: ${pageRoute}`;
    if (!externalId) {
        // Home page
        [content, errors] = await fetchContent({
            contentTypes: ['homePage']
        });
        logger(location, content, errors);
        props = await withNavigation({
            content,
            errors
        });
    }
    else {
        const mergedId = externalId.join('/');
        if (!isValidGenericPageURL(mergedId)) {
            return { notFound: true, revalidate: DEFAULT_REVALIDATE };
        }
        const params = {
            contentTypes: ['page'],
            externalId: mergedId
        };
        [content, errors] = await fetchContent(params);
        logger(location, content, errors);
        if (errors || content.length <= 0) {
            return { notFound: true, revalidate: ERROR_REVALIDATE };
        }
        if (content[0]?.body?.contentSlots.some((obj) => obj.contentTypeId === 'userIdentityChecklist')) {
            const [patronusData] = await fetchAllPagedContent({ contentTypes: ['patronus'] });
            const patronusArray = patronusData.map((item) => item.body);
            content[0].body.patronusData = patronusArray;
        }
        props = await withNavigation({
            content: content[0].body
        });
    }
    let revalidate = DEFAULT_REVALIDATE;
    const timeout = content[0].body.revalidationTime;
    if (Number.isInteger(timeout) && timeout > 0) {
        revalidate = content[0].body.revalidationTime;
    }
    return {
        props,
        revalidate
    };
}
export async function getStaticPaths() {
    return {
        fallback: 'blocking',
        paths: []
    };
}
DynamicPage.propTypes = propTypes;
export default DynamicPage;

    async function __Next_Translate__getStaticProps__191c29da304__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[...externalId]',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191c29da304__ as getStaticProps }
  